<template>
    <div v-if="formObj != null && !formLoading">
      <AppForms
        v-if="formObj != null && !formLoading"
        :defaultItem="defaultItem"
        :editObj="formObj"
        :getRestData="getRestData"
        :formFields="restFields"
        ref="restForm"
      />
    </div>
    <div
    v-else-if="formLoading"
    class="text-center py-5 d-flex align-items-center justify-content-center"
  >
    <b-spinner small class="mx-1" /> {{ $t("Loading...") }}
  </div>
</template>

<script>
import {
  BSpinner
} from "bootstrap-vue";
import { mapGetters, mapActions } from "vuex";
import AppForms from "./AppForms.vue";

export default {
  components: {
    BSpinner,
    AppForms,
  },
  data() {
    return {
      formLoading: false,
      rolesLoader: false,
      formData: {},
      formObj: null,
      defaultItem: {},
    };
  },
  props: ["editObj", "closeModal"],
  mounted() {
    this.formLoading = true;
    this.getRoles({});
    if (this.editObj != null) {
      this.getUser(this.editObj.id).then((res) => {
        this.formObj = { ...res.data.results };
        this.formData.id = this.editObj.id;
        this.formLoading = false;
      });
    } else {
      this.formData = {};
      this.formObj = {
        status: true,
      };
      this.formLoading = false;
    }
  },
  methods: {
    ...mapActions(["getRoles", "createUser", "getFilteredUsers", "getUser"]),
    formSubmit() {
      this.$refs.restForm.submit().then((res) => {
        if (res == true) {
          this.$store.commit("setModalLoading", true);
          setTimeout(() => {
            this.createUser(this.formData).then((res) => {
              this.closeModal();
              this.getFilteredUsers({});
              this.$store.commit("setModalLoading", false);
            });
          }, 100);
        }
      });
    },
    getRestData(data) {
      this.formData = {
        ...this.formData,
        ...data,
      };
    },
    searchRoles(searchText) {
      this.rolesLoader = true;
      this.getRoles({
        keywords: searchText,
      }).then(res => {
      this.rolesLoader = false;
      }).catch(err => {
      this.rolesLoader = false;

      })
    },
  },
  computed: {
    ...mapGetters(["roles"]),
    titleRoles() {
      return this.roles.map((el) => {
        return {
          ...el,
          title: el.titles[this.locale],
        };
      });
    },
    restFields() {
      return [
        {
          name: this.$t("name"),
          type: "text",
          rules: "",
          model: "name",
        },
        {
          name: this.$t("email"),
          type: "email",
          rules: "email",
          model: "email",
        },
        {
          name: this.$t("role"),
          type: "searchSelect",
          rules: "required",
          isLoading:this.rolesLoader,
          model: "role_id",
          options: this.titleRoles,
          label: "title",
          searchItems: this.searchRoles,
          reducer:item=>item.id
        },
        {
          name: this.$t("phone"),
          type: "phoneNumber",
          inputId: "UserProfileFormPhoneUpdate",
          place: 0,
          rules: "",
          model: "phone",
          cols: 6,
        },
        {
          name: this.$t("password"),
          type: "password",
          rules: "",
          model: "password",
        },
        {
          name: "Status",
          type: "searchSelect",
          options: [
            {
              title: this.$t("Active"),
              id: true,
            },
            {
              title: this.$t("Disabled"),
              id: false,
            },
          ],
          reducer: (item) => item.id,
          label: "title",
          model: "status",
          searchItems: (t) => t,
          initialValue: true,
          cols: 6,
          rules: "required",
        },
      ];
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
