<template>
  <validation-observer ref="clientRules" class="">
    <b-form @submit.prevent="submit">
      <b-row>
        <b-col cols="2" class="align-self-end my-1">
          <validation-provider #default="{ errors }" name="preRegisteredUser">
            <!-- <label for='Status'>
              {{$t('preRegisteredUser')}}
            </label>  -->
            <b-form-checkbox
              v-model="clientData.preRegisteredUser"
              class="custom-control-primary"
              @input="checkRegisteredClient"
            >
              {{ $t("preRegisteredUser") }}
            </b-form-checkbox>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <b-col>
          <validation-provider #default="{ errors }" name="client">
            <label for="client">
              {{ $t("client") }}
            </label>
            <v-select
              id="clent"
              v-model="clientData.user"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name"
              :options="users"
              :disabled="!clientData.preRegisteredUser"
              @input="setUser"
              @search="searchUsers"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>

      <b-card no-body class="p-3 my-2">
        <h4>{{ "client Information" }}</h4>
        <b-row>
          <b-col>
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="name"
            >
              <label for="name">
                {{ $t("name") }}
              </label>
              <b-form-input
                type="text"
                v-model="clientData.name"
              ></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col>
            <validation-provider
              #default="{ errors }"
              rules="required|integer|min:8"
              name="phone"
            >
              <label for="phone">
                {{ $t("phone") }}
              </label>
              <b-form-input
                type="tel"
                v-model="clientData.phone"
              ></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row class="my-1">
          <b-col>
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="area_no"
            >
              <label for="area_no">
                {{ $t("area_no") }}
              </label>
              <b-form-input
                type="text"
                v-model="clientData.area_no"
              ></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col>
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="street"
            >
              <label for="street">
                {{ $t("street") }}
              </label>
              <b-form-input
                type="text"
                v-model="clientData.street"
              ></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col>
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="home_no"
            >
              <label for="home_no">
                {{ $t("home_no") }}
              </label>
              <b-form-input
                type="text"
                v-model="clientData.home_no"
              ></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row class="my-1">
          <b-col>
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="dayTime"
            >
              <label for="dayTime">
                {{ $t("dayTime") }}
              </label>
              <v-select
                :options="onceTypes"
                :reduce="(type) => type.code"
                v-model="clientData.dayTime"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col>
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="day"
            >
              <label for="day">
                {{ $t("day") }}
              </label>
              <v-select
                :options="daysItems"
                :reduce="(day) => day.id"
                v-model="clientData.day"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col>
            <validation-provider #default="{ errors }" name="notes">
              <label for="notes">
                {{ $t("notes") }}
              </label>
              <b-form-textarea
                type="text"
                v-model="clientData.notes"
              ></b-form-textarea>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>
      </b-card>
    </b-form>
  </validation-observer>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import vSelect from "vue-select";
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTableLite,
  BCardText,
  BButton,
  BAlert,
  BLink,
  VBToggle,
  BFormSelect,
  BForm,
  BFormGroup,
  BFormCheckbox,
  BFormInput,
  BFormTextarea,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
} from "@validations";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    VBToggle,
    BFormSelect,
    BForm,
    BFormGroup,
    BFormCheckbox,
    BFormInput,
    BFormTextarea,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },

  data() {
    return {
      clientData: {
        preRegisteredUser: false,
        user: {},
        id: null,
        name: "",
        phone: "",
        area_no: "",
        street: "",
        home_no: "",
        area_id: null,
        day: "",
        dayTime: "",
        notes: "",
      },

      repeatTypes: [6, 0, 1, 2, 3, 4],

      required,
    };
  },
  watch: {},
  props: ["editObj", "getClientData"],
  mounted() {
    this.getUsers({
      role: "users",
    });
  },
  methods: {
    ...mapActions(["getUsers"]),
    searchUsers(searchText) {
      this.getUsers({
        role: "users",
        keywords: searchText,
      });
    },
    setUser(data) {
      this.clientData.id = data.id;
      this.clientData.name = data.name;
      this.clientData.phone = data.phone;
    },
    checkRegisteredClient(checked) {
      if (checked == false) {
        this.clientData.user = {};
      }
    },
    submit() {
      return this.$refs.clientRules.validate().then((res) => {
        if (res == true) {
          this.getClientData(this.clientData);
          return true;
        } else {
          return false;
        }
      });
    },
  },

  computed: {
    ...mapGetters(["users"]),
    onceTypes() {
      return [
        { id: 1, label: this.$t("afternoon"), code: "am" },
        { id: 2, label: this.$t("midday"), code: "an" },
        { id: 3, label: this.$t("evening"), code: "pm" },
      ];
    },
    daysItems() {
      return [
        { id: 6, label: this.$t("saturday") },
        { id: 0, label: this.$t("sunday") },
        { id: 1, label: this.$t("monday") },
        { id: 2, label: this.$t("tuesday") },
        { id: 3, label: this.$t("wednesday") },
        { id: 4, label: this.$t("thursday") },
        //  {id: 5, label: this.$t('afternoon'), },
      ];
    },
  },
};
</script>