<template>
  <validation-observer ref="simpleRules">
    <b-form @submit.prevent="submit">
      <b-row>
        <template v-for="(field, fIndex) in formFields">
          <b-col
            :key="fIndex"
            sm="12"
            :md="field.cols == 'auto' ? '' : field.cols || 6"
            class="align-self-center"
            v-if="
              field.vifValue == styleSelected.target ||
              field.vifValue == styleSelected.image ||
              field.vifValue == styleSelected.map ||
              !field.vifAttribute ||
              (field.vifAttribute &&
                formData[field.vifAttribute] == field.vifValue)
            "
          >
            <div>
              <b-form-group :label="$t(field.name)" :label-for="field.name">
                <validation-provider
                  #default="{ errors }"
                  :rules="`${field.rules}`"
                  :name="field.name"
                >
                  <v-select
                    v-if="field.type == 'searchSelect'"
                    :id="field.name"
                    :options="field.options"
                    :label="field.label"
                    :reduce="(item) => item.id"
                    v-model="formData[field.model]"
                    :dir="locale == 'ar' ? 'rtl' : 'ltr'"
                    :disabled="field.disabled"
                    @search="field.searchItems"
                    @change="selectedTarget(formData[field.model])"
                  >
                    <template
                      v-if="
                        field.name == 'page' || field.name == 'Page Target ID'
                      "
                      #option="{ titles }"
                    >
                      <p style="margin: 0">
                        {{ titles[locale] }}
                      </p>
                    </template>
                    <template #selected-option="data">
                      <p
                        style="margin: 0"
                        class="text-nowrap"
                        v-if="data.titles"
                      >
                        {{ data.titles[locale] }}
                      </p>
                      <p
                        style="margin: 0"
                        v-else-if="data && data.id && data.title"
                      >
                        {{ data.title ? data.title.slice(0, 20) : "" }}...
                      </p>
                      <p style="margin: 0" v-else-if="data.key">
                        {{ data.key }}
                      </p>
                    </template>
                  </v-select>

                  <v-select
                    v-else-if="field.type == 'searchSelectItem'"
                    :id="field.name[locale]"
                    :options="field.options"
                    :label="field.label"
                    :reduce="(item) => item"
                    v-model="formData[field.model]"
                    :disabled="field.disabled"
                    @search="field.searchItems"
                  >
                  </v-select>
                  <b-form-select
                    v-else-if="field.type == 'select'"
                    :id="field.name"
                    :options="field.options"
                    @input="
                      selectAnotherTarget(formData[field.model], field.options)
                    "
                    :text-field="field.label"
                    value-field="value"
                    v-model="formData[field.model]"
                    :disabled="field.disabled"
                  >
                  </b-form-select>
                  <b-form-checkbox
                    v-else-if="field.type == 'checkbox'"
                    v-model="formData[field.model]"
                    :id="field.name"
                    switch
                    inline
                  >
                  </b-form-checkbox>
                  <div v-else-if="field.type == 'divider'" class="w-100">
                    <hr v-if="field.line == true" />
                  </div>
                  <b-input-group
                    v-else
                    :dir="field.name == 'path' ? 'ltr' : ''"
                    :class="field.name == 'path' ? 'text-left' : ''"
                  >
                    <b-input-group-prepend v-if="field.name == 'path'">
                      <b-button
                        class="rounded"
                        variant="flat-primary"
                        style="
                          padding-right: 2px !important;
                          padding-left: 7px !important;
                        "
                      >
                        {{ field.placeholder }}
                      </b-button>
                    </b-input-group-prepend>
                    <b-form-input
                      :type="field.type"
                      :id="field.model"
                      v-if="field.name == 'Target button Text'"
                      v-model="formData[field.model][nameLang]"
                      :state="errors.length > 0 ? false : null"
                    />
                    <b-form-input
                      v-else
                      :class="[field.name == 'path' ? 'pl-0' : '', 'rounded']"
                      :style="
                        field.name == 'path'
                          ? 'padding-left: 2px !important'
                          : ''
                      "
                      :type="field.type"
                      :id="field.name"
                      v-model="formData[field.model]"
                      :state="errors.length > 0 ? false : null"
                    />
                    <b-input-group-append
                      v-if="field.name == 'Target button Text'"
                    >
                      <b-dropdown :text="nameLang" variant="outline-primary">
                        <b-dropdown-item
                          v-for="lang in langs"
                          :key="lang"
                          @click="changeNameLang(lang)"
                        >
                          {{ $t(lang) }}
                        </b-dropdown-item>
                      </b-dropdown>
                    </b-input-group-append>
                  </b-input-group>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </b-col>
        </template>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BFormSelect,
  BInputGroupPrepend,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
} from "@validations";
import vSelect from "vue-select";

import { mapActions, mapGetters } from "vuex";
import VueDraggableResizable from "vue-draggable-resizable";

// optionally import default styles
import "vue-draggable-resizable/dist/VueDraggableResizable.css";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroupPrepend,
    BForm,
    BButton,
    BFormTextarea,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    VueDraggableResizable,
  },
  data() {
    return {
      styleSelected: {},
      formData: {},
      nameLang: "en",
      descLang: "en",
      required,
      password,
      email,
      url,
      confirmed,
      between,
      parent: false,
      mapImage: require("../../assets/map.jpg"),
      x: 50,
      y: 50,
      mapHeight: 340,
      mapWidth: 0,
      mapEl: null,
    };
  },
  watch: {},
  props: [
    "defaultItem",
    "getRestData",
    "editObj",
    "formFields",
    "styleChanged",
  ],
  mounted() {
    if (this.editObj != null && this.editObj != "null") {
      this.formData = this.editObj;
      console.log(this.formData);
      this.formData.button_text = {
        ...this.editObj.button_text,
      };
      if (this.editObj.status && this.editObj.status == 1) {
        this.formData.status = true;
      }
    } else if (this.editObj == undefined || this.editObj == null) {
      this.formData = {
        button_text: {
          ar: "",
          en: "",
          de: "",
        },
      };
      this.formData.status = true;
      this.$forceUpdate();
    } else {
      this.formData = {
        ...this.defaultItem,
        button_text: {
          ar: "",
          en: "",
          de: "",
        },
      };
      this.$forceUpdate();
    }
    this.$forceUpdate();
  },
  methods: {
    selectedTarget(d) {
      console.log(d);
    },
    changeNameLang(lang) {
      this.nameLang = lang;
    },
    submit() {
      return this.$refs.simpleRules.validate().then((res) => {
        if (res == true) {
          this.getRestData(this.formData);
          return true;
        } else {
          return false;
        }
      });
    },
    onDrag: function (x, y) {
      this.parent = true;
      this.x = x;
      this.y = y;
      this.formData.left = parseInt((this.x / this.mapWidth) * 100);
      this.formData.top = parseInt((this.y / (this.mapHeight - 70)) * 100);
      // if(this.x < this.mapWidth  && this.x > 0) {
      // }
      // if(this.y < this.mapHeight  && this.y > 0) {
      // }
      // this.$forceUpdate()
      // this.formObj.left = parseInt((this.x/ this.mapWidth) *100);
      // this.formObj.top = parseInt((this.y/ this.mapHeight) *100);
    },
    selectAnotherTarget(tar, bar) {
      if (this.$route.name == "Sections") {
        const style = bar.find((s) => s.value == tar);
        this.styleSelected = style;
        this.$forceUpdate();
        this.styleChanged(this.styleSelected);
      }
    },
  },
  computed: {
    ...mapGetters(["newsList"]),
    exampleFields() {
      return [
        {
          name: "searchSelect",
          type: "searchSelect",
          rules: "required",
          options: ["items"],
          label: "label",
          model: "selectAttr",
          disabled: false,
          searchItems: (searchText) => console.log("searching", searchText),
        },
        {
          name: "select",
          type: "select",
          rules: "required",
          options: ["items"],
          label: "label",
          model: "selectAttr",
        },
        {
          name: "checkbox",
          type: "checkbox",
          rules: "required",
          model: "selectAttr",
        },
        {
          name: "color",
          type: "color",
          placeholder: "placeholder",
          rules: "required",
          model: "selectAttr",
        },
        {
          name: "phone",
          type: "tel",
          placeholder: "placeholder",
          rules: "required",
          model: "selectAttr",
          vifAttribute: "field.vifAttribute",
          vifValue: "field.vifValue",
        },
      ];
    },
  },
  directives: {
    Ripple,
  },
};
</script>

<style>
.nav-tabs{
  margin-bottom: 16px !important;
}
</style>