<template>
  <div>
    <b-form @submit.prevent="submit">
      <label for="file-input">
        <span v-if="$route.name == 'Sections'">
          {{ $t("Media") }}
        </span>
        <span v-else>
          {{ $t("Images") }}
        </span>
      </label>
      <b-form-file
        ref="file-input"
        id="file-input"
        v-model="file"
        class="mb-2"
        :multiple="multiple ? multiple : false"
      />
      <!-- <b-button
        variant="primary"
        @click="file = null"
        size="sm"
      >
        {{$t('Reset')}}
      </b-button> -->
      <div
        class="p-1"
        v-if="
          (base64 && base64.file) ||
          (editObj && editObj.image_url) ||
          (editObj && editObj.media_url)
        "
      >
        <!-- <img
          v-if="$route.name == 'Projects'"
          :src="editObj.images[0] && editObj.images[0].url"
          width="10%"
          height="100%"
        />
        <img
          v-if="$route.name == 'Categories'"
          :src="editObj.icon_url"
          width="10%"
          height="100%"
        />
        <img
          v-if="$route.name == 'Sections'"
          :src="editObj.media_url"
          width="10%"
          height="100%"
        /> -->
        <div class="d-flex">
          <div class="image-holder">
            <img
              v-if="base64.file"
              :src="base64.file"
              width="150px"
              height="150px"
            />
            <img
              v-else-if="editObj.media_url"
              :src="editObj.media_url"
              width="150px"
              height="150px"
              style="object-fit: cover"
            />
            <img
              v-else-if="editObj.image_url"
              :src="editObj.image_url"
              width="150px"
              height="150px"
              style="object-fit: cover"
            />
            <span class="deleteIconImages">
              <b-button
                variant="danger"
                size="sm"
                @click="deleteImage"
                v-if="base64.file"
              >
                <feather-icon icon="Trash2Icon" size="12"></feather-icon>
              </b-button>
            </span>
          </div>
        </div>
        <!-- <img v-if="base64.file" :src="base64.file" width="20%" height="100%" /> -->

        <!-- <div class="my-1" v-if='editObj.icon_url || (editObj.images[0] && editObj.images[0].url)'> -->
        <!-- <b-button
            variant="danger"
            class="btn"
            @click="deleteImg"
          >
            <feather-icon icon="Trash2Icon" />
            delete
          </b-button> -->
        <!-- </div> -->
      </div>
    </b-form>
  </div>
</template>

<script>
import {
  BFormFile,
  VBTooltip,
  BButton,
  BForm,
  BCard,
  BCardFooter,
} from "bootstrap-vue";
import { mapActions } from "vuex";
export default {
  components: {
    BFormFile,
    BButton,
    BForm,
    BCard,
    BCardFooter,
  },
  props: ["getImgData", "editObj", "getMediaData", "multiple"],
  data: () => ({
    file: null,
    base64: {
      ext: "",
      file: "",
    },
  }),
  directives: {
    "b-tooltip": VBTooltip,
  },
  methods: {
    ...mapActions(["createProduct"]),
    submit() {
      if (this.file) {
        if (this.$route.name == "Sections") {
          this.getMediaData(this.file);
        } else {
          this.getBase64(this.file).then((res) => {
            this.getImgData(res);
            this.base64 = res;
            return true;
          });
        }
      }
      // this.getImgForm()
    },
    deleteImage() {
      (this.file = null),
        (this.base64 = {
          ext: "",
          file: "",
        });
    },
  },
  watch: {
    file(nf) {
      if (nf) {
        this.getBase64(nf).then((res) => {
          this.base64 = res;
        });
      }
    },
  },
  computed: {
    urlRoutes() {
      let routes = ["Labels", "Testmonials", "Banners", "News", "Services"];
      return routes.includes(this.$route.name);
    },
  },
};
</script>
 <style scoped>
.deleteIconImages {
  position: absolute;
  right: 10px;
  bottom: 5px;
}

.image-holder {
  position: relative;
  width: 150px;
}
.deleted {
  filter: blur(1px) brightness(60%);
}

.drag-parent {
  padding: 0 !important;
  width: 100%;
  height: 300px;
  background-position: center;
  background-size: cover;
  margin: 10px 20px;
  border-radius: 4px;
}

.drag-element {
  /* background: red; */
  border: 0;
}
</style>