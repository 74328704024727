<template>
  <div class="" v-if="!formLoading">
    <BaseForm
      v-if="formObj != null && !formLoading"
      :editObj="formObj"
      :getBaseData="getBaseData"
      ref="baseForm"
    />
    <hr />
    <ResponsiveFieldsForm
      v-if="formObj != null && !formLoading"
      :defaultItem="defaultItem"
      :editObj="formObj"
      :getRestData="getRestData"
      :formFields="restFields"
      ref="restForm"
    />
    <!-- <hr/> -->
    <ImgForm
      :editObj="formObj"
      :getMediaData="getMediaData"
      :getImgData="getImgData"
      ref="imgForm"
    />
    <b-row v-if="formObj != null && !formLoading">
      <b-col>
        <b-form-group :label="$t('Requirements')" label-for="requirement">
          <quill-editor v-model="formData.requirment" :options="snowOption" />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group
          :label="$t('Responsibilities')"
          label-for="Responsibilities"
        >
          <quill-editor
            v-model="formData.Responsibilities"
            :options="snowOption"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// import ResponsiveForm from './ResponsiveForm.vue'
import ResponsiveFieldsForm from "./ResponsiveFieldsForm.vue";
import BaseForm from "./BaseForm.vue";
import ProductRestForm from "./ProductRestForm.vue";
import ImgForm from "./ImgForm.vue";
// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

import { BRow, BCol, BFormGroup } from "bootstrap-vue";

import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    ResponsiveFieldsForm,
    BaseForm,
    ProductRestForm,
    ImgForm,
    BRow,
    BCol,
    BFormGroup,
    quillEditor,
  },
  data() {
    return {
      formObj: null,
      formLoading: false,
      formData: {},
      defaultItem: {
        // color: '#FFFFFF',
        status: true,
      },
    };
  },

  props: ["editObj", "closeModal"],
  mounted() {
    if (this.editObj != null) {
      this.getJob(this.editObj.id).then((res) => {
        this.formObj = res.data.results;
        this.formObj.location = this.formObj.location.en;
        this.formObj.salary_from = this.formObj.salary_avrage.from;
        this.formObj.salary_to = this.formObj.salary_avrage.to;
        this.formObj.job_category = this.formObj.category.id;
        this.formObj = {
          ...this.formObj,
          ...this.formObj.conatacts,
        };
        this.formData.Responsibilities = this.formObj.Responsibilities;
        this.formData.requirment = this.formObj.requirment;
        this.formData.id = this.editObj.id;
        setTimeout(() => {
          this.formLoading = false;
        }, 100);
      });
      // this.getLabel(this.editObj.id)
      //   .then(res => {
      //     this.formData.id = this.editObj.id;
      //     this.formLoading = false;
      //   })
    } else {
      this.formObj = {
        status: true,
      };
    }
  },
  methods: {
    ...mapActions(["getPages", "getJobs", "createJob", "getJob"]),
    formSubmit() {
      this.$refs.restForm.submit().then((res) => {
        if (res == true) {
          if (res == true) {
            this.$store.commit("setModalLoading", true);
            this.$refs.restForm.submit();
            this.$refs.baseForm.submit();
            this.$refs.imgForm.submit();
            setTimeout(() => {
              this.createJob(this.formData).then((res) => {
                this.closeModal();
                this.getJobs({});
                this.$store.commit("setModalLoading", false);
              });
            }, 100);
          }
        }
      });
    },

    getBaseData(data) {
      this.formData.titles = data.titles;
      this.formData.descriptions = data.descriptions;
    },
    getImgData(data) {
      this.formData.base64 = [];
      if (this.editObj && this.editObj.image) {
        this.formData.base64 = [
          {
            ...this.editObj.image,
            delete: true,
          },
        ];
      }

      this.formData.base64 = [...this.formData.base64, data];
    },

    getRestData(data) {
      this.formData = {
        ...this.formData,
        ...data,
        conatacts: {
          facebook: data.facebook,
          linkedin: data.linkedin,
          email: data.email,
          twitter: data.twitter,
        },
        experince_level: data.experience_level,
      };
    },
    getMediaData(data) {
      if (this.isFileImage(data)) {
        this.formData.media_type = 0;
      } else {
        this.formData.media_type = 1;
      }
      this.formData.base64 = data;
    },
    getRepeaterData(data) {
      this.formData.items = data;
    },
    checkNulldesc() {
      if (
        this.formData.descriptions.ar == null ||
        this.formData.descriptions.en == null
      ) {
        if (this.formData.descriptions.ar == null) {
          this.formData.descriptions.ar = "";
        }
        if (this.formData.descriptions.en == null) {
          this.formData.descriptions.en = "";
        }
      }
    },
    searchJobCategories(searchText) {
      this.getJobCategories({
        keywords: searchText,
      });
    },
  },

  computed: {
    ...mapGetters(["product", "modalLoading", "jobCategories"]),
    restFields() {
      return [
        { name: "location", type: "text", rules: "", model: "location" },
        // {name: 'type', type: 'text', rules: 'required', model: 'type' },
        {
          name: "experience",
          type: "text",
          rules: "",
          model: "experience_level",
        },

        { name: "linkedin", type: "text", rules: "", model: "linkedin" },
        { name: "facebook", type: "text", rules: "", model: "facebook" },
        { name: "email", type: "email", rules: "", model: "email" },
        { name: "twitter", type: "text", rules: "", model: "twitter" },

        {
          name: "salary from",
          type: "number",
          rules: "",
          model: "salary_from",
        },
        { name: "salary to", type: "number", rules: "", model: "salary_to" },

        {
          name: "status",
          type: "checkbox",
          rules: "required",
          model: "status",
        },
        {
          name: "job_category",
          type: "searchSelect",
          rules: "required",
          model: "job_category",
          options: this.jobCategories,
          label: "title",
          searchItems: this.searchJobCategories,
          cols: 12,
        },
      ];
    },
  },
};
</script>