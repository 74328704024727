<template>
  <b-form @submit.prevent="submit">
    <b-row>
      <b-col
        cols="4"
        v-for="permission in allPermissions"
        :key="permission.key"
        v-show="!noPermissionKeys(permission.key)"
      >
        <h4>
          {{ permission.key }}
        </h4>
        <b-table
          responsive
          :items="permission.permissions"
          :fields="permissionsFields"
          class="my-2"
          outlined
          small
          hover
        >
        <template #cell(name)="data">
            <label class="pointer w-100" :for="data.item.name+data.item.permission"
            >
          {{ data.item.name }}
          </label>
          </template>
        <template #cell(truth)="data">
            <b-form-checkbox
            :id="data.item.name+data.item.permission"
              v-model="permissionsData[data.item.permission]"
            ></b-form-checkbox>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
} from "bootstrap-vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
  },
  data() {
    return {
      permissionsData: {},
    };
  },
  props: ["editObj", "getPermissionsData"],
  mounted() {
    if (this.editObj != null && this.editObj.permissions) {
      let perms = {};
      for (const [key, value] of Object.entries(this.editObj.permissions)) {
        perms[value] = true;
      }
      this.permissionsData = { ...perms };
    }
  },
  methods: {
    ...mapActions(["getRoles"]),
    submit() {
      let perms = [];
      for (const [key, value] of Object.entries(this.permissionsData)) {
        if (value == true) {
          perms.push(key);
        }
      }
      this.getPermissionsData(perms);
    },
    keyNeededPerms(permission) {
      let keys = ["transactions", "suppliers", "receipts"];
      return keys.includes(permission.key) ? true : false;
    },
    noPermissionKeys(key) {
      let perms = ["championships", "labels", "testimonials"];
      return perms.includes(key);
    },
  },
  computed: {
    ...mapGetters(["userProfile", "filteredUser", "permissions", "roles"]),
    allPermissions() {
      return Object.values(this.permissions);
    },
    permissionsFields() {
      return [
        {
          key: "name",
          label: this.$t("title"),
        },
        {
          key: "truth",
        },
      ];
    },
  },
};
</script>