<template>
  <div v-if="!formLoading && formObj">
    <BaseForm
      :titleOnly="true"
      :editObj="formObj"
      :getBaseData="getBaseData"
      ref="baseForm"
    />
    <AppForms
      :editObj="formObj"
      :getRestData="getRestData"
      :formFields="restFields"
      ref="restForm"
    />
  </div>
  <div
    v-else-if="formLoading"
    class="text-center py-5 d-flex align-items-center justify-content-center"
  >
    <b-spinner small class="mx-1" /> {{ $t("Loading...") }}
  </div>
</template>

<script>
import AppForms from "./AppForms.vue";
import { BSpinner } from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
import BaseForm from "./BaseForm.vue";
export default {
  components: {
    BSpinner,
    AppForms,
    BaseForm,
  },
  data() {
    return {
      formObj: null,
      categoriesLoader: false,
      formLoading: false,
      formData: {},
    };
  },

  props: ["editObj", "closeModal", "loadNews"],
  mounted() {
    this.getCategories({});
    if (this.editObj != null) {
      this.formLoading = true;
      this.loadShowedNews(this.editObj.id)
        .then((res) => {
          this.formObj = { ...res.data.results };
          this.formObj.important = res.data.results.other_info.important || false;
          this.formData.id = this.editObj.id;
          this.imgPrev = this.formObj.image_url;
          setTimeout(() => {
            this.formLoading = false;
          }, 100);
        })
        .catch((err) => {
          this.$store.commit("setModalLoading", false);
          this.handleEvent({
            variant: "danger",
            icon: "XIcon",
            msg: err && err.response ? err.response.data.results.message : err,
          });
        });
    } else {
      this.formObj = {
        status: true,
        titles: {},
        other_info: {
          important: true,
        },
      };
    }
  },
  methods: {
    ...mapActions([
      "loadShowedNews",
      "updateNews",
      "loadNewsList",
      "createNews",
      "getCategories",
    ]),
    formSubmit() {
      this.$refs.baseForm.submit().then((res) => {
        if (res == true) {
          this.$refs.restForm.submit().then((baseRes) => {
            if (baseRes == true) {
              this.$store.commit("setModalLoading", true);
              setTimeout(() => {
                this.createNews(this.formData)
                  .then((res) => {
                    if (this.loadNews) {
                      this.loadNews();
                    }
                    this.closeModal();
                    this.$store.commit("setModalLoading", false);
                  })
                  .catch((err) => {
                    this.$store.commit("setModalLoading", false);
                    this.handleEvent({
                      variant: "danger",
                      icon: "XIcon",
                      msg: err && err.response ? err.response.data.results.message : err,
                    });
                  });
              }, 100);
            }
          });
        }
      });
    },
    getBaseData({ titles }) {
      this.formData.titles = { ...titles };
    },
    getRestData(data) {
      this.formData = {
        ...this.formData,
        ...data,
        titles: {
          ...this.formData.titles,
        },
      };
    },
    searchCategories(searchText) {
      this.categoriesLoader = true;
      this.getCategories({
        keywords: searchText,
      })
        .then((res) => {
          this.categoriesLoader = false;
        })
        .catch((err) => {
          this.categoriesLoader = false;
        });
    },
  },

  computed: {
    ...mapGetters(["modalLoading", "categories"]),
    restFields() {
      return [
        {
          name: "description",
          type: "localizedHTML",
          model: "descriptions",
          cols: 12,
          rules: "required",
        },
        {
          name: "Categories",
          type: "searchSelect",
          options: this.categories,
          label: "title",
          isLoading: this.categoriesLoader,
          model: "category_id",
          reducer: (item) => item.id,
          searchItems: this.searchCategories,
          cols: 4,
          rules: "required",
        },
        {
          name: "Status",
          type: "searchSelect",
          options: [
            {
              title: this.$t("Active"),
              id: true,
            },
            {
              title: this.$t("Disabled"),
              id: false,
            },
          ],
          reducer: (item) => item.id,
          label: "title",
          model: "status",
          searchItems: (t) => t,
          initialValue: true,
          cols: 3,
          rules: "required",
        },
        {
          name: "Important",
          type: "searchSelect",
          options: [
            {
              title: this.$t("Yes"),
              id: true,
            },
            {
              title: this.$t("No"),
              id: false,
            },
          ],
          reducer: (item) => item.id,
          label: "title",
          model: "important",
          searchItems: (t) => t,
          initialValue: false,
          cols: 3,
          rules: "",
        },
        {
          name: "Sort",
          type: "number",
          model: "ord",
          cols: 2,
          rules: "",
        },
        {
          name: "Image",
          model: "image_file",
          cols: 12,
          thePlaceholder: this.$t("Upload/Update Image"),
          type: "fileUpload",
          rules: "",
          accept: "image/*",
          exImgPrev: "image_url",
          imgPrev: "image",
          fileClass: "full",
          mainClass: "flex-column",
          multiple: false,
        },
      ];
    },
  },
};
</script>
