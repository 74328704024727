<template>
    <AppForms
      v-if="formObj != null && !formLoading"
      :editObj="formObj"
      :getRestData="getRestData"
      :formFields="restFields"
      ref="restForm"
    />
</template>

<script>
import AppForms from "./AppForms.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    AppForms,
  },
  data() {
    return {
      formObj: {},
      formLoading: false,
      formData: {},
    };
  },

  props: ["editObj", "closeModal"],
  methods: {
    ...mapActions(["sendMail"]),
    formSubmit() {
      this.$refs.restForm.submit().then((res) => {
        if (res == true) {
          this.$store.commit("setModalLoading", true);
          this.$store.commit("setModalLoading", false);
          this.closeModal();
        }
      });
    },
    getRestData(data) {
      this.formData = {
        ...data,
      };
    },
  },

  computed: {
    ...mapGetters(["modalLoading"]),
    restFields() {
      return [
        {
          name: "Title",
          type: "localizedText",
          model: "titles",
          cols: 12,
          rules: "required",
        },
        {
          name: "description",
          type: "localizedHTML",
          model: "descriptions",
          cols: 12,
          rules: "required",
        },
      ];
    },
  },
};
</script>