<template>
  <div class="" v-if="!formLoading">
    <AppForms
      :editObj="formObj"
      :getRestData="getSectionData"
      :formFields="inputs"
      ref="sectionsForm"
    />
  </div>
  <div class="d-flex justify-content-center align-items py-5" v-else>
    <b-spinner label="Loading section info..." />
  </div>
</template>

<script>
import AppForms from "./AppForms.vue";
import { BRow, BCol, BFormInput, BFormFile, BFormGroup, BSpinner } from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    AppForms,
    BSpinner,
    BRow,
    BCol,
    BFormInput,
    BFormFile,
    BFormGroup,
  },
  data() {
    return {
      formObj: {
        preview: true,
        status: true,
      },
      formLoading: false,
      formData: {},
      slidersLoader: false,
      pagesLoader: false,
      pages2Loader: false,
      servicesLoader: false,
      newsLoader: false,
    };
  },
  props: ["editObj", "closeModal", "loadSections"],
  mounted() {
    ["sliders", "pages", "services", "news"].forEach((item, index) => {
      this.search({
        type: item,
      })
    });
    if(this.editObj){
      this.formLoading = true;
      this.getSection(this.editObj.id).then(res=>{
        const data = res.data.results;
        this.formObj = {
          titles: data.titles,
          descriptions: data.descriptions,
          status: data.status,
          page_id: data.page?{
            ...data.page,
            label: data.page.titles?data.page.titles[this.$i18n.locale]:data.page.title
          }:data.page_id,
          slider: data.custom_fields.slider,
          ord: data.ord,
          style: this.sectionStyles.find(el => el.data.key === (data.custom_fields.key||data.custom_fields.section_key)),
          action_method: data.custom_fields.action_method,
          action_target: data.custom_fields.action_target,
          action_text: data.custom_fields.action_text,
          view_all: data.custom_fields.view_all,
          media_url: data.media_url,
          map_lat: data.custom_fields.map_lat,
          map_lng: data.custom_fields.map_lng,
        }
        if(this.formObj.action_target && typeof this.formObj.action_target==='object'){
          ['names','titles'].forEach(el=>{
            const content = this.formObj.action_target[el]||this.formObj.action_target[el.replace('s','')]
            if(content){
              this.formObj.action_target = {
                ...this.formObj.action_target,
                label: content[this.$i18n.locale]||content
              }
            }
          })
        }
        this.formLoading = false;
      })
    }
  },
  methods: {
    ...mapActions([
      "getPages",
      "getSections",
      "createSection",
      "getSection",
      "getLabels",
      "getSliders",
      "loadNewsList",
      "loadServices",
    ]),
    getSectionData(data) {
      if(data.action_text){
        delete data.action_text.locale
      }
      this.formData = {
        id: this.editObj?.id,
        titles: data.titles,
        descriptions: data.descriptions,
        page_id: data.page_id && data.page_id.id ? data.page_id.id : data.page_id,
        status: data.status,
        ord: data.ord,
        media: data.image_base64,
        custom_fields: {
          map_lat: data.map_lat,
          map_lng: data.map_lng,
          section_key: data.style.data.key,
          slider: data.slider?{
            id:data.slider.id,
            key:data.slider.key
          }:null,
          action_method: data.action_method,
          action_target: data.action_target && typeof data.action_target === 'object'?window.cleanObject({
            path: data.action_target.path,
            id: data.action_target.id,
            title: data.action_target.title,
            titles: data.action_target.titles,
            name: data.action_target.name,
            names: data.action_target.names,
          }):data.action_target,
          action_text: window.cleanObject(data.action_text),
          view_all: data.view_all,
        },
      };
    },
   async search(options = {}) {
      this[`${options.type}Loader`] = true;
      let sender = null;
      switch (options.type) {
        case "pages":
          sender = this.getPages;
          break;
        case "sliders":
          sender = this.getSliders;
          break;
        case "services":
          sender = this.loadServices;
          break;
        case "news":
          sender = this.loadNewsList;
          break;
      }
     return sender({
        [options.key || "blabla"]: options.text,
      })
        .then((res) => {
          this[`${options.type}Loader`] = false;
        })
        .catch((err) => {
          this[`${options.type}Loader`] = false;
        });
    },
    formSubmit() {
      this.$refs.sectionsForm.submit().then((valid) => {
        if (valid) {
          setTimeout(()=>{
            this.createSection(this.formData).then(res=>{
              this.closeModal();
              if(this.loadSections){
                this.loadSections();
              }
            })
          },100)
        }
      });
    },
  },

  computed: {
    ...mapGetters(["pages", "sliders", "services", "newsList"]),
    inputs() {
      return [
        {
          name: "preview section",
          type: "image",
          rules: "",
          model: "section_image_preview",
          cols: 12,
          message: "Please select the section type to see the preview.",
          remove: !this.formObj.preview,
        },
        {
          name: "Section title",
          type: "localizedText",
          model: "titles",
          cols: 12,
          rules: "required",
        },
        {
          name: "Short description",
          type: "localizedTextarea",
          model: "descriptions",
          cols: 12,
          rules: "",
          remove: !this.formObj.style || !this.formObj.style.data.has_short_description
        },
        {
          name: "Content",
          type: "localizedHTML",
          model: "descriptions",
          cols: 12,
          rules: "required",
          remove: !this.formObj.style || !this.formObj.style.data.has_description
        },
        {
          name: "Section type",
          type: "searchSelect",
          rules: "required",
          model: "style",
          options: this.sectionStyles,
          label: "title",
          cols: 6,
          selectItem: (item) => {
            this.formObj.section_image_preview = require(`../../../assets/images/sections/${item.data.key}.png`);
            const keys = ["slider", "action_method", "action_target", "view_all"];
            keys.forEach((key) => {
              delete this.formObj[key];
            });
            this.formObj.action_text = {
              ar: "",
              en: "",
              de: "",
              locale: this.$i18n.locale,
            };
            this.$refs.sectionsForm.$forceUpdate();
            this.$forceUpdate();
          },
        },
        {
          name: "Target page",
          type: "searchSelect",
          rules: "required",
          model: "page_id",
          options: this.titledItems("pages"),
          searchItems: (t) => this.search({ text: t, key: "keywords", type: "pages" }),
          cols: 6,
          isLoading: this.pagesLoader,
        },
        {
          name: "Map center latitude",
          type: "text",
          rules: "",
          model: "map_lat",
          cols: 6,
          remove: !this.formObj.style || !this.formObj.style.data.has_map,
        },
        {
          name: "Map center longitude",
          type: "text",
          rules: "",
          model: "map_lng",
          cols: 6,
          remove: !this.formObj.style || !this.formObj.style.data.has_map,
        },
        {
          name: "View all items",
          type: "searchSelect",
          rules: "required",
          model: "view_all",
          reducer: (item) => item.value,
          options: [
            {
              label: "View all with pagination",
              value: true,
            },
            {
              label: "View latest items only",
              value: false,
            },
          ],
          cols: 12,
          remove: !this.formObj.style || !this.formObj.style.data.view_all,
        },
        {
          name: "Target slider",
          type: "searchSelect",
          rules: "required",
          model: "slider",
          options: this.sliders,
          label: "key",
          isLoading: this.slidersLoader,
          searchItems: (t) =>
            this.search({
              text: t,
              key: "key",

              type: "sliders",
            }),
          cols: 12,
          remove: !this.formObj.style || !this.formObj.style.data.is_slider,
        },
        {
          name: "Action button text",
          type: "localizedText",
          rules: "",
          model: "action_text",
          cols: this.formObj.action_method ? 4 : 6,
          remove: !this.formObj.style || !this.formObj.style.data.has_action,
          input: ()=>{
            this.$refs.sectionsForm.$forceUpdate();
            this.$forceUpdate();
          }
        },
       
        {
          name: "Action method",
          type: "searchSelect",
          rules: this.formObj.action_text && (this.formObj.action_text.en||this.formObj.action_text.ar||this.formObj.action_text.de)?"required":"",
          model: "action_method",
          reducer: (item) => item.value,
          input: () => {
            this.formObj.action_target = null;
            this.$refs.sectionsForm.$forceUpdate();
          },
          options: [
            {
              label: "Open page",
              value: "page",
            },
            {
              label: "Open service",
              value: "service",
            },
            {
              label: "Open news",
              value: "news",
            },
            {
              label: "Open url",
              value: "url",
            },
          ],
          cols: this.formObj.action_method ? 4 : 6,
          remove: !this.formObj.style || !this.formObj.style.data.has_action,
        },
        {
          name: "Which page?",
          type: "searchSelect",
          rules: this.formObj.action_method?"required":"",
          model: "action_target",
          options: this.titledItems("pages"),
          searchItems: (t) => this.search({ text: t, key: "keywords", type: "pages" }),
          isLoading: this.pagesLoader,
          cols: this.formObj.action_method ? 4 : 6,
          remove:
            !this.formObj.style ||
            !this.formObj.style.data.has_action ||
            this.formObj.action_method !== "page",
        },
        {
          name: "Which service?",
          type: "searchSelect",
          rules: "required",
          model: "action_target",
          options: this.titledItems("services"),
          searchItems: (t) =>
            this.search({
              text: t,
              key: "title",

              type: "service",
            }),
          isLoading: this.servicesLoader,
          cols: this.formObj.action_method ? 4 : 6,
          remove:
            !this.formObj.style ||
            !this.formObj.style.data.has_action ||
            this.formObj.action_method !== "service",
        },
        {
          name: "Which news?",
          type: "searchSelect",
          rules: "required",
          model: "action_target",
          options: this.titledItems("newsList"),
          searchItems: (t) => this.search({ text: t, key: "title", type: "news" }),
          isLoading: this.newsLoader,
          cols: this.formObj.action_method ? 4 : 6,
          remove:
            !this.formObj.style ||
            !this.formObj.style.data.has_action ||
            this.formObj.action_method !== "news",
        },
        {
          name: "Which URL?",
          type: "url",
          rules: "required|url",
          model: "action_target",
          cols: this.formObj.action_method ? 4 : 6,
          remove:
            !this.formObj.style ||
            !this.formObj.style.data.has_action ||
            this.formObj.action_method !== "url",
        },
        {
          name: "Section order",
          type: "number",
          placeholder: "section place in the page",
          rules: "required",
          model: "ord",
          cols: 4,
        },
        {
          name: "Section visibility",
          type: "searchSelect",
          rules: "required",
          model: "status",
          reducer: (item) => item.value,
          options: [
            { value: true, label: "Visible" },
            { value: false, label: "Hidden" },
          ],
          cols: 4,
        },
        {
          name: "Section preview",
          type: "searchSelect",
          rules: "",
          model: "preview",
          reducer: (item) => item.value,
          options: [
            { value: true, label: "Yes" },
            { value: false, label: "Hide preview" },
          ],
          cols: 4,
          initialValue: false
        },
      
        {
          name: "Section image",
          model: "image_file",
          cols: 12,
          thePlaceholder: this.$t("Upload/Update Image"),
          type: "fileUpload",
          rules: "",
          accept: "image/*",
          exImgPrev: "media_url",
          imgPrev: "image",
          fileClass: "full",
          mainClass: "flex-column",
          multiple: false,
          remove: !this.formObj.style || !this.formObj.style.data.has_image,
        },
      ];
    },
    titledItems() {
      return (type) =>
        this[type].map((item) => {
          return {
            ...item,
            label: item.titles ? item.titles[this.$i18n.locale] : item.title,
          };
        });
    },
  },
};
</script>
