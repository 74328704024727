<template>
  <validation-observer ref="simpleRules">
    <b-form @submit.prevent='submit'>
      <label for='file-input'>
        {{$t('Images')}}
      </label>  
      <validation-provider
        #default="{ errors }"
        rules="required"
        name='start'
      >
        <b-form-file
          ref="file-input"
          id='file-input'
          v-model="file"
          class="mb-2"
        />
      <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form>
  </validation-observer >
</template>

<script>
import { BFormFile, BButton, BForm, BCard, BCardFooter } from 'bootstrap-vue'
import {mapActions} from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
} from '@validations'

export default {
  components: {
    BFormFile,
    BButton,
    BForm,
    BCard, BCardFooter,
    ValidationProvider, ValidationObserver
  },
  props: ['getFileData', 'editObj'],
  data: () => ({
    file: null,
    base64: {
      ext: '',
      file: '',
    },
    required,
  }),
  methods: {
    ...mapActions(['createProduct']),
    submit() {
      return this.$refs.simpleRules.validate() 
        .then(res => {
          if(this.file && res == true) {
            this.getBase64(this.file)
              .then(res => {
                this.getFileData(res)
              })
            return true;
          }else {
            return false;
          }
        })
      // this.getImgForm()
    },
       
        
  },
}
</script>
 