<template>
  <validation-observer ref="simpleRulesouter" class="">
    <b-form @submit.prevent="formSubmit">
      <h4 class="mb-1">
        {{ $t("client") }}
      </h4>
      <ClientOrderForm
        :editObj="formObj"
        ref="clientForm"
        :getClientData="getClientData"
      />
      <hr />
      <b-row class="my-1" v-if="!modalLoading && !formLoading">
        <b-col>
          <h4 class="mb-1">
            {{ $t("Products") }}
          </h4>
          <ClientOrderRepeater
            :editObj="formObj"
            ref="clientRepeater"
            :getRepeaterData="getRepeaterData"
          />
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import BaseForm from "./BaseForm";
import ImgForm from "./ImgForm";
import RestFormCat from "./RestFormCat";
import ClientOrderForm from "./ClientOrderForm";
import vSelect from "vue-select";
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTableLite,
  BCardText,
  BButton,
  BAlert,
  BLink,
  VBToggle,
  BFormSelect,
  BForm,
  BFormGroup,
} from "bootstrap-vue";
import ClientOrderRepeater from "./ClientOrderRepeater";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  components: {
    BaseForm,
    ImgForm,
    ClientOrderForm,
    ClientOrderRepeater,
    RestFormCat,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    VBToggle,
    BFormSelect,
    BForm,
    BFormGroup,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      formData: {
        supplier_id: "",
        on_date: new Date(),
        status: null,
      },
      formObj: null,
      formLoading: false,
    };
  },
  props: ["editObj", "closeModal"],
  mounted() {
    this.formLoading = true;
    this.$store.commit("setModalLoading", true);
    if (this.editObj != null) {
      this.formObj = this.editObj;
      this.formData.id = this.editObj.id;
    }
    this.getProducts({}).then((res) => {
      this.$store.commit("setModalLoading", false);
      this.formLoading = false;
    });
  },
  methods: {
    ...mapActions([
      "makeOrder",
      "getOrders",
      "getCategory",
      "createReciept",
      "getProducts",
    ]),
    formSubmit() {
      this.$refs.clientForm.submit().then((res) => {
        if (res == true) {
          this.$refs.clientRepeater.submit().then((res) => {
            if (res == true) {
              this.$store.commit("setModalLoading", true);
              // this.$refs.baseForm.submit()
              // this.$refs.imgForm.submit()
              // this.getDateNow()
              // this.$refs.recipetRepeater.submit()
              this.makeOrder(this.formData)
                .then((res) => {
                  this.getOrders({});
                  this.closeModal();
                  this.$store.commit("setModalLoading", false);
                })
                .catch((err) => console.log(err));
            } else {
              return false;
            }
          });
        }
      });
    },
    getClientData(data) {
      this.formData.client = data;
      // this.formData.descriptions = data.descriptions;
      this.formData.recurring = {
        each: null,
        at: data.dayTime,
        dat: data.day,
      };
    },
    getImgData(data) {
      this.formData.base64 = data;
    },
    getRepeaterData(data) {
      this.formData = {
        ...this.formData,
        cart: [...data],
      };
    },
    searchSuppliers(searchText) {
      this.getSuppliers({
        keywords: searchText,
      });
    },
    getDateNow() {
      let dt = new Date(Date.now());
      dt = `${dt.getFullYear().toString().padStart(4, "0")}-${(
        dt.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${dt.getDate().toString().padStart(2, "0")} ${dt
        .getHours()
        .toString()
        .padStart(2, "0")}:${dt.getMinutes().toString().padStart(2, "0")}:${dt
        .getSeconds()
        .toString()
        .padStart(2, "0")}`;

      this.formData.on_date = dt;
    },
  },
  computed: {
    ...mapGetters(["suppliers", "loading", "modalLoading"]),
  },
};
</script>
    
    