<template>
  <div class="" v-if="!formLoading && formObj != null">
    <BaseForm
      :titleOnly="true"
      :editObj="formObj"
      :getBaseData="getBaseData"
      ref="baseForm"
    />
    <AppForms
      :editObj="formObj"
      :getRestData="getRestData"
      :formFields="restFields"
      ref="restForm"
    />
  </div>
  <div
    v-else-if="formLoading"
    class="text-center py-5 d-flex align-items-center justify-content-center"
  >
    <b-spinner small class="mx-1" /> {{ $t("Loading...") }}
  </div>
</template>

<script>
import AppForms from "./AppForms.vue";
import { mapActions, mapGetters } from "vuex";
import { BSpinner } from "bootstrap-vue";
import BaseForm from "./BaseForm.vue";

export default {
  components: {
    AppForms,
    BSpinner,
    BaseForm,
  },
  data() {
    return {
      formObj: null,
      formLoading: false,
      formData: {},
      sliderLoader: false,
      pagesLoader: false,
      servicesLoader: false,
      newsLoader: false,
    };
  },

  props: ["editObj", "closeModal","load"],
  mounted() {
    if (this.editObj != null) {
      this.formLoading = true;
      this.getBanner(this.editObj.id)
        .then((res) => {
          if(!res.data.results.target) res.data.results.target = {};
          this.formObj = {
            ...res.data.results,
            action_method: res.data.results.target.type,
            action_target: res.data.results.target.target,
            action_text: res.data.results.target.button,
          };
          this.formData.id = this.editObj.id;
          this.formObj.status = this.editObj.status ? true : false;
          setTimeout(() => {
            this.formLoading = false;
          }, 100);
        })
        .catch((err) => {
          this.formLoading = false;
          this.$store.commit("setModalLoading", false);
          this.handleEvent({
            variant: "danger",
            icon: "XIcon",
            msg: err && err.response ? err.response.data.results.message : err,
          });
        });
    } else {
      this.formObj = {
        titles: {},
      };
      this.formLoading = false;
    }
  },
  created() {
    this.searchNews();
    this.searchPages();
    this.searchServices();
    this.searchSliders();
  },
  methods: {
    ...mapActions([
      "getBanners",
      "createBanner",
      "getBanner",
      "getPages",
      "loadServices",
      "loadNewsList",
      "getSliders",
    ]),
    formSubmit() {
      this.$refs.baseForm.submit().then((res) => {
        if (res == true) {
          this.$refs.restForm.submit().then((res) => {
            if (res == true) {
              this.$store.commit("setModalLoading", true);

              setTimeout(() => {
                this.createBanner(this.formData)
                  .then(() => {
                    if(this.load){
                      this.load()
                    }
                    this.closeModal();
                    this.$store.commit("setModalLoading", false);
                  })
                  .catch((err) => {
                    this.$store.commit("setModalLoading", false);
                    this.handleEvent({
                      variant: "danger",
                      icon: "XIcon",
                      msg: err && err.response ? err.response.data.results.message : err,
                    });
                  });
              }, 100);
            }
          });
        }
      });
    },
    getBaseData({ titles }) {
      this.formData.titles = { ...titles };
    },
    getRestData(data) {
      this.formData = {
        ...this.formData,
        ...data,
        titles: {
          ...this.formData.titles,
        },
      };
    },
    searchSliders(searchText) {
      this.sliderLoader = true;
      this.getSliders({
        key: searchText,
        status: true,
      })
        .then((res) => {
          this.sliderLoader = false;
        })
        .catch((err) => {
          this.sliderLoader = false;

          this.handleEvent({
            variant: "danger",
            icon: "XIcon",
            msg: err && err.response ? err.response.data.results.message : err,
          });
        });
    },
    searchPages(searchText) {
      this.pagesLoader = true;
      this.getPages({
        key: searchText,
        status: 1,
      })
        .then((res) => {
          this.pagesLoader = false;
        })
        .catch((err) => {
          this.pagesLoader = false;

          this.handleEvent({
            variant: "danger",
            icon: "XIcon",
            msg: err && err.response ? err.response.data.results.message : err,
          });
        });
    },
    searchServices(searchText) {
      this.servicesLoader = true;
      this.loadServices({
        key: searchText,
        status: 1,
      })
        .then((res) => {
          this.servicesLoader = false;
        })
        .catch((err) => {
          this.servicesLoader = false;

          this.handleEvent({
            variant: "danger",
            icon: "XIcon",
            msg: err && err.response ? err.response.data.results.message : err,
          });
        });
    },
    searchNews(searchText) {
      this.newsLoader = true;
      this.loadNewsList({
        key: searchText,
        status: 1,
      })
        .then((res) => {
          this.newsLoader = false;
        })
        .catch((err) => {
          this.newsLoader = false;

          this.handleEvent({
            variant: "danger",
            icon: "XIcon",
            msg: err && err.response ? err.response.data.results.message : err,
          });
        });
    },
  },

  computed: {
    ...mapGetters(["modalLoading", "sliders", "pages", "services", "newsList"]),
    restFields() {
      return [
        {
          name: "description",
          type: "localizedHTML",
          model: "descriptions",
          cols: 12,
          rules: "required",
        },
        {
          name: "Slider",
          type: "searchSelect",
          rules: "required",
          model: "slider_id",
          options: this.sliders,
          reducer: (item) => item.id,
          isLoading: this.sliderLoader,
          label: "key",
          searchItems: this.searchSliders,
          cols: 6,
        },
        {
          name: "Status",
          type: "searchSelect",
          options: [
            {
              title: this.$t("Active"),
              id: true,
            },
            {
              title: this.$t("Disabled"),
              id: false,
            },
          ],
          reducer: (item) => item.id,
          label: "title",
          model: "status",
          searchItems: (t) => t,
          initialValue: true,
          cols: 3,
          rules: "required",
        },
        {
          name: "Sort no",
          type: "number",
          model: "ord",
          cols: 3,
          rules: "",
        },
        {
          name: "Action type",
          type: "searchSelect",
          options: this.targetTypes,
          rules: "required",
          model: "action_method",
          searchItems: (t) => t,
          reducer: (item) => item.id,
          searchable: false,
          label:"title",
          selectItem: () => {
            this.formObj.action_target = null;
          },
          cols: 6,
        },
        {
          name: "Which page?",
          type: "searchSelect",
          options: this.pages.map((el) => {
            return {
              ...el,
              title: el.titles ? el.titles[this.$i18n.locale] : el.title,
            };
          }),
          label: "title",
          searchItems: this.searchPages,
          isLoading: this.pagesLoader,
          rules: "required",
          model: "action_target",
          cols: 6,
          remove: this.formObj.action_method != "page",
        },
        {
          name: "Which news?",
          type: "searchSelect",
          options: this.newsList,
          label: "title",
          searchItems: this.searchNews,
          isLoading: this.newsLoader,
          rules: "required",
          model: "action_target",
          cols: 6,
          remove: this.formObj.action_method != "news",

        },
        {
          name: "Which service?",
          type: "searchSelect",
          options: this.services,
          searchItems: this.searchServices,
          isLoading: this.servicesLoader,
          label: "title",
          rules: "required",
          model: "action_target",
          cols: 6,
          remove: this.formObj.action_method != "service",

        },
        {
          name: "Which URL?",
          type: "text",
          rules: "required|url",
          model: "action_target",
          cols: 6,
          remove: this.formObj.action_method != "url",
        },
        {
          name: "Action button text",
          type: "localizedText",
          rules: "required",
          model: "action_text",
          cols: 12,
          remove: this.formObj.action_method == null || this.formObj.action_method == "none",
        },
        {
          type: "divider",
          line: true,
          cols: 12,
        },
        {
          name: "Image",
          model: "image_file",
          cols: 12,
          thePlaceholder: this.$t("Upload/Update Image"),
          type: "fileUpload",
          rules: "",
          accept: "image/*",
          exImgPrev: "image_url",
          imgPrev: "image",
          fileClass: "full",
          mainClass: "flex-column",
          multiple: false,
        },
      ];
    },
    // imgStylesForm() {
    //   return [
    //     {
    //       name: "Image Filter",
    //       type: "input",
    //       rules: "required",
    //       model: "image_filter",
    //       label: "key",
    //       // searchItems: this.searchSliders,
    //       cols: 12,
    //     },
    //   ];
    // },
    targetTypes() {
      return [
        {
          title: this.$t("News"),
          id: "news",
        },
        {
          title: this.$t("Service"),
          id: "service",
        },
        {
          title: this.$t("Page"),
          id: "page",
        },
        {
          title: this.$t("URL"),
          id: "url",
        },
        {
          title: this.$t("None"),
          id: "none",
        },
      ];
    },
    filterTypes() {
      return ["overlay", "blur"];
    },
  },
};
</script>
