<template>
  <div class="">
    <div v-if="formObj != null && !formLoading">
      <AppForms
        :editObj="formObj"
        :getRestData="getRestData"
        :formFields="restFields2"
        ref="restForm"
      />
    </div>
    <div
    v-else-if="formLoading"
    class="text-center py-5 d-flex align-items-center justify-content-center"
  >
    <b-spinner small class="mx-1" /> {{ $t("Loading...") }}
  </div>
  </div>
</template>
  
<script>
import { mapGetters, mapActions } from "vuex";
import AppForms from "./AppForms.vue";
import { BSpinner } from "bootstrap-vue";

export default {
  components: {
    AppForms,
    BSpinner
  },
  data() {
    return {
      formLoading: false,
      formData: {},
      formObj: null,
      defaultItem: {},
      error: null,
    };
  },
  props: ["editObj", "closeModal"],
  mounted() {
    this.formLoading = true;
    this.getUserProfile().then((res) => {
      this.formLoading = false;
      this.formObj = {
        ...res.data.results,
      };
    }).catch(err => {
      this.formLoading = false;

    })
  },
  methods: {
    ...mapActions(["getUserProfile", "UserProfileUpdate", "getUser"]),
    formSubmit() {
      this.$refs.restForm.submit().then((res) => {
        if (res == true) {
          if (
            (this.formData.password || this.formData.password_confirmation) &&
            !this.formData.current_password
          ) {
            this.handleEvent({
              icon: "XIcon",
              type: "danger",
              msg: this.$t("Please Enter Current Password First"),
            });
            return false;
          } else if (
            this.formData.current_password ||
            this.formData.password ||
            this.formData.password_confirmation
          ) {
            if (
              this.formData.current_password == this.formData.password ||
              this.formData.current_password ==
                this.formData.password_confirmation
            ) {
              this.handleEvent({
                icon: "XIcon",
                type: "danger",
                msg: this.$t("New Password is the to current password"),
              });

              return false;
            } else if (
              this.formData.password != this.formData.password_confirmation
            ) {
              this.handleEvent({
                icon: "XIcon",
                type: "danger",
                msg: this.$t("password not comfirmed"),
              });
              return false;
            }
          }
          setTimeout(() => {
            this.$store.commit("setModalLoading", true);
            this.UserProfileUpdate(this.formData)
              .then((res) => {
                this.closeModal();
                this.$store.commit("setModalLoading", false);
                this.getUserProfile();
              })
              .catch((err) => {
                this.handleEvent({
                  icon: "XIcon",
                  type: "danger",
                  msg: this.$t("error", {
                    error:
                      err.response.status == 422
                        ? err.response.data.results.message
                        : err,
                  }),
                });
                this.$store.commit("setModalLoading", false);
              });
          }, 100);
        }
      });
    },

    getBaseData(data) {
      this.formData.titles = data.titles;
      // this.formData.descriptions = data.descriptions;
    },
    getRestData(data) {
      this.formData = {
        ...this.formData,
        ...data,
      };
    },
    getPermissionsData(data) {
      this.formData.permissions = data;
    },
    searchRoles(searchText) {
      this.getRoles({
        keywords: searchText,
      });
    },
    searchBranchs(text) {
      this.isBrsloading = true;
      this.getBranches({
        keywords: text,
      })
        .then(() => {
          this.isBrsloading = false;
        })
        .catch((err) => {
          this.handleEvent(
            {
              icon: "XIcon",
              type: "defErr",
            },
            err
          );
        });
    },
  },
  computed: {
    ...mapGetters(["userProfile", "user"]),
    titleRoles() {
      return this.roles.map((el) => {
        return {
          ...el,
          title: el.titles[this.i18nlocale],
        };
      });
    },
    titleBranchs() {
      return this.branches.map((el) => {
        return {
          ...el,
          title: el.names[this.i18nlocale],
        };
      });
    },
    restFields2() {
      return [
        {
          type: "groupTitle",
          title: this.$t("baisc info"),
          cols: 12,
        },
        {
          name: this.$t("name"),
          type: "text",
          rules: "",
          model: "name",
          cols: 4,
        },
        {
          name: this.$t("email"),
          type: "email",
          rules: "email",
          model: "email",
          cols: 4,
        },
        {
          name: this.$t("phone"),
          type: "phoneNumber",
          inputId: "AdminProfileFormPhoneUpdate",
          place: 0,
          rules: "",
          model: "phone",
          cols: 4,
        },
        {
          type: "groupTitle",
          title: this.$t("Change Password"),
          cols: 12,
        },
        {
          name: this.$t("current password"),
          type: "password",
          rules: "",
          model: "current_password",
          cols: 4,
        },
        {
          name: this.$t("new password"),
          type: "password",
          rules: "",
          model: "password",
          cols: 4,
        },
        {
          name: this.$t("password confirmation"),
          type: "password",
          rules: "",
          model: "password_confirmation",
          cols: 4,
        },
        {
          name: this.$t("Update Profile Picture"),
          model: "base_file",
          cols: 12,
          type: "fileUpload",
          rules: "",
          accept: "image/*",
          imgPrev: "avatar",
          exImgPrev: "avatar_url",
          multiple: false,
        },
        // {
        //   name: this.$t("status"),
        //   type: "checkbox",
        //   rules: "required",
        //   model: "status",
        //   cols: 2
        // },
      ];
    },
  },
};
</script>
  
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
  