<template>
  <b-card no-body class="invoice-preview-card">
    <!-- Header -->
    <b-card-body class="invoice-padding pb-0">
      <div
        class="
          d-flex
          justify-content-between
          flex-md-row flex-column
          invoice-spacing
          mt-0
        "
      >
        <!-- Header: Left Content -->
        <div>
          <div class="logo-wrapper">
            <h3 class="text-primary invoice-logo">
              <img
                src="@/assets/images/logo/NoPath.png"
                width="5%"
                height="5%"
              />
              <span class="mx-1">Strta</span>
            </h3>
          </div>
          <p class="card-text mb-25">
            <!-- {{editObj.address.street}},
            {{editObj.address.home_no}}, -->
          </p>
          <p class="card-text mb-25">
            <!-- {{editObj.area ? editObj.area.name : ''}} -->
          </p>
          <p class="card-text mb-0">
            <!-- {{editObj.address.phone}} -->
          </p>
        </div>

        <!-- Header: Right Content -->
        <div class="mt-md-0 mt-2">
          <h4 class="invoice-title">
            {{ $t("Invoice") }}
            <span class="invoice-number">#{{ editObj.id }}</span>
          </h4>
          <h4 class="invoice-title">
            {{ $t("Status") }} :
            <strong class="invoice-number">{{
              getRecipetTitle(editObj.status)
            }}</strong>
          </h4>
          <div class="invoice-date-wrapper">
            <p class="invoice-date-title">{{ $t("date") }}:</p>
            <p class="invoice-date">
              {{
                this.formateDate(editObj) != false
                  ? this.formateDate(editObj)
                  : "--/--/---"
              }}
            </p>
          </div>
        </div>
      </div>
    </b-card-body>
    <!-- Spacer -->
    <hr class="invoice-spacing" />
    <!-- Invoice Description: Table -->
    <b-table-lite
      v-if="editObj && editObj.id"
      responsive
      :items="editObj.items"
      :fields="fields"
    >
    </b-table-lite>
    <hr class="invoice-spacing" />
    <!-- Invoice Description: Total -->
    <b-card-body class="invoice-padding pb-0">
      <b-row>
        <!-- Col: Sales Persion -->
        <b-col
          cols="12"
          md="4"
          class="mt-md-0 mt-3 mb-1"
          order="2"
          order-md="1"
        >
          <b-card-text class="mb-0">
            <span class="font-weight-bold">{{ $t("Supplier") }}:</span>
            <span class="ml-75">{{ editObj.supplier.name }}</span>
          </b-card-text>
          <b-card-text class="mb-0">
            <span class="font-weight-bold">{{ $t("Supplier Phone") }}:</span>
            <span class="ml-75">{{ editObj.supplier.phone }}</span>
          </b-card-text>
          <b-card-text class="mb-0">
            <span class="font-weight-bold">{{ $t("Notes") }}:</span>
            <span class="ml-75">{{ editObj.supplier.notes }}</span>
          </b-card-text>
        </b-col>

        <!-- Col: Total -->
        <b-col
          cols="12"
          md="8"
          class="mt-md-6 d-flex justify-content-end"
          order="1"
          order-md="2"
        >
          <div class="invoice-total-wrapper">
            <span class="invoice-total-item">
              <!-- <p class="invoice-total-title">
                Subtotal:
                <span class=" mx-1 invoice-total-amount">
                  {{editObj.total - (editObj.area ? editObj.area.delivery_charge : settings.ShippingRules.default)}}
                </span>
              </p> -->
            </span>
            <div class="invoice-total-item">
              <!-- <p class="invoice-total-title">
                Delivery:
                <span class=" mx-1 invoice-total-amount">
                  {{editObj.area ? editObj.area.delivery_charge : settings.ShippingRules.default}}
                </span>
              </p> -->
            </div>
            <!-- <hr class="my-50"> -->
            <div class="invoice-total-item">
              <p class="invoice-total-title">
                {{ $t("total") }}:
                <span class="mx-1 invoice-total-amount">
                  {{ editObj.value }}
                </span>
              </p>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>


<script>
import { ref, onUnmounted } from "@vue/composition-api";
import store from "@/store";
import router from "@/router";
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTableLite,
  BCardText,
  BButton,
  BAlert,
  BLink,
  VBToggle,
  BFormSelect,
  BForm,
  BFormGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { title } from "@/@core/utils/filter";
import { mapActions, mapGetters } from "vuex";

export default {
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    BFormSelect,
    BForm,
    BFormGroup,
  },
  data() {
    return {
      fields: [
        {
          key: "product_id",
          label: this.$t("Product ID"),
        },
        {
          key: "product.title",
          label: this.$t("Product Name"),
        },
        {
          key: "item_id",
          label: this.$t("code"),
        },
        {
          key: "quantity",
          label: this.$t("quantity"),
        },
        {
          key: "value",
          label: this.$t("Unit Price"),
        },
        {
          key: "net_value",
          label: this.$t("totalAfterDiscount"),
          formatter: (value, key, item) => (value == null ? item.value : value),
        },
        {
          key: "total",
          label: this.$t("totalPaid"),
          formatter: (value, key, item) =>
            value == null ? item.value * item.quantity : value * item.quantity,
        },
      ],
      payOptions: [
        { id: 1, text: this.$t("paid"), value: true },
        { id: 2, text: this.$t("not paid"), value: false },
      ],
      statusData: {
        payment: false,
        delivery_id: 0,
      },
    };
  },
  props: ["editObj", "closeModal"],
  mounted() {
    this.statusData.payment =
      this.editObj.payments_status == true ? true : false;
    this.statusData.delivery_id = this.editObj.status_id;
  },
  methods: {
    ...mapActions([
      "getSettings",
      "getOrdersStatus",
      "updateOrder",
      "getOrders",
    ]),
    formSubmit() {
      this.closeModal();
    },
    getItemInsideProduct(product) {
      let item = product.product.items.find((el) => el.id == product.item_id);
      return item.titles[this.locale];
    },
  },

  computed: {
    ...mapGetters(["settings", "ordersStatus"]),
  },
};
</script>




<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
