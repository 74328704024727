<template>
  <div class="" v-if="!formLoading">
    <BaseForm :editObj="formObj" :getBaseData="getBaseData" ref="baseForm" />
    <hr />
    <ResponsiveFieldsForm
      :defaultItem="defaultItem"
      :editObj="formObj"
      :getRestData="getRestData"
      :formFields="restFields"
      ref="restForm"
    />
    <!-- <hr/> -->
    <!-- <ProductRestForm :editObj='formObj' :getRestData='getRestData' ref='restForm'/> -->
    <!-- <hr/> -->
    <!-- <ImgForm 
      :editObj='formObj' 
      :getImgData='getImgData' 
      ref='imgForm'
    /> -->
    <ImgFormMulti
      :editObj="formObj"
      :getMediaData="getMediaData"
      :getImgData="getImgData"
      ref="imgForm"
      :multiple="true"
    />
  </div>
</template>

<script>
// import ResponsiveForm from './ResponsiveForm.vue'
import ResponsiveFieldsForm from "./ResponsiveFieldsForm.vue";
import BaseForm from "./BaseForm.vue";
import ProductRestForm from "./ProductRestForm.vue";
import ImgForm from "./ImgForm.vue";
import ImgFormMulti from "./ImgFormMulti.vue";

import { BRow, BCol } from "bootstrap-vue";

import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    ResponsiveFieldsForm,
    BaseForm,
    ProductRestForm,
    ImgForm,
    BRow,
    BCol,
    ImgFormMulti,
  },
  data() {
    return {
      formObj: null,
      formLoading: true,
      formData: {},
      defaultItem: {
        top: 50,
        left: 50,
        status: true,
      },
    };
  },
  watch: {},
  props: ["editObj", "closeModal"],
  mounted() {
    if (this.editObj != null) {
      this.formLoading = true;
      this.$forceUpdate();
      this.getCategories({});
      this.getSolution(this.editObj.id).then((res) => {
        this.formObj = res.data.results;
        this.formObj.descriptions = { ...this.formObj.description };
        this.formObj.top = this.formObj.postion.top;
        this.formObj.left = this.formObj.postion.left;
        this.formData.id = this.editObj.id;
        this.formLoading = false;
      });
    } else {
      setTimeout(() => {
        this.formObj = {
          top: 50,
          left: 50,
          status: true,
        };

        this.formLoading = false;
      }, 300);
    }
  },
  methods: {
    ...mapActions([
      "getSolutions",
      "getSolution",
      "createSolution",
      "getCategories",
    ]),
    formSubmit() {
      this.$refs.baseForm.submit().then((res) => {
        if (res == true) {
          this.$refs.restForm.submit().then((res) => {
            if (res == true) {
              this.$store.commit("setModalLoading", true);
              this.$refs.restForm.submit();
              this.$refs.imgForm.submit();
              this.$refs.baseForm.submit();
              setTimeout(() => {
                this.createSolution(this.formData).then((res) => {
                  this.closeModal();
                  this.getSolutions({});
                  this.$store.commit("setModalLoading", false);
                });
              }, 200);
            }
          });
        }
      });
    },

    getBaseData(data) {
      this.formData.titles = data.titles;
      this.formData.descriptions = data.descriptions;
    },
    getImgData(data) {
      this.formData.base64 = [];
      this.formData.base64 = [...this.formData.base64, ...data];
    },
    getMediaData(data) {
      if (this.isFileImage(data)) {
        this.formData.media_type = 0;
      } else {
        this.formData.media_type = 1;
      }
      this.formData.base64 = data;
    },
    getRestData(data) {
      this.formData = {
        ...this.formData,
        ...data,
      };
    },
    getRepeaterData(data) {
      this.formData.items = data;
    },
    checkNulldesc() {
      if (
        this.formData.descriptions.ar == null ||
        this.formData.descriptions.en == null
      ) {
        if (this.formData.descriptions.ar == null) {
          this.formData.descriptions.ar = "";
        }
        if (
          this.formData.descriptions.en == null ||
          this.formData.descriptions.en == ""
        ) {
          this.formData.descriptions.en = this.formData.descriptions.ar;
        }
      }
    },
    searchCategories(searchText) {
      this.getCategories({
        keywords: searchText,
      });
    },
  },

  computed: {
    ...mapGetters(["solution", "modalLoading", "categories"]),
    filteredCategories() {
      return this.categories.filter((el) => el.parent_id == 11);
    },
    restFields() {
      return [
        { name: "top", type: "number", rules: "between:10, 90", model: "top" },
        {
          name: "left",
          type: "number",
          rules: "between:10, 90",
          model: "left",
        },
        // {name: 'details', type: 'text', rules: 'required', model: 'details_url' },
        {
          name: "Video Url",
          type: "text",
          rules: "required",
          model: "video_url",
        },
        {
          name: "Details Url",
          type: "text",
          rules: "required",
          model: "details_url",
        },
        {
          name: "Projects Category",
          type: "searchSelectItem",
          options: this.filteredCategories,
          label: "title",
          searchItems: this.searchCategories,
          rules: "required",
          model: "category",
        },
        {
          name: "status",
          type: "checkbox",
          rules: "required",
          model: "status",
        },
      ];
    },
  },
};
</script>